import { graphql, Link } from 'gatsby';
import moment from 'moment/moment';
import React from 'react';
import { Column, Container, Row } from '../components/Grid';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Video from '../components/Video';

const Blog = ({ data }) => {
  const blog = data.blog;
  return (
    <Layout title={blog.title} className="news-and-events blog has-video">
      {/* Intro Title and Video */}
      <Section>
        <Container>
          {/* Breadcrumbs */}
          <ul className="breadcrumbs no-hero">
            <li className="breadcrumb">
              <Link to="../../../../news-and-events/recent-news">News</Link>
            </li>
            <li className="breadcrumb">
              <Link to="../../../../news-and-events/ysc-blog">
                Young Scientist Blog
              </Link>
            </li>
            <li className="breadcrumb">{moment(blog.date).format('YYYY')}</li>
            <li className="breadcrumb active">{blog.title}</li>
          </ul>
          <Row>
            <Column size={10} offset={1} className="no-hero">
              <h1 className="page-title">{blog.title}</h1>
              {/* Blog Post Video Container */}
              {blog.video && (
                <div className="video-container__with-pager">
                  <div className="videoWrapper">
                    <Video youTubeID={blog.video} className="youtube"></Video>
                    {/* <Video src={blog.video} className="youtube"></Video>*/}
                  </div>
                  {/* <Link to="../peaks-and-valleys" className="next">
                  <Icon name="arrowrightgray" />
                </Link> */}
                </div>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
      {/* Main Blog Content */}
      <Section className="pb-4">
        <Container>
          <Row>
            <Column size={8} offset={1}>
              <h3 className="name-date">
                {blog.author && <span>By {blog.author} | </span>}
                {moment(blog.date).format('MMMM DD, YYYY')}
              </h3>
              {blog.text.map((paragraph, index) => (
                <p dangerouslySetInnerHTML={{ __html: paragraph}} key={index} />
              ))}
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    blog: blogsYaml(slug: { eq: $slug }) {
      title
      text
      video
      author
      date
      thumbnail
    }
  }
`;

export default Blog;
